import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/ryan-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/ryan"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">

      <b>Trading Macro</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Using macro news to identify future trends
        </li>
        <li>
          Learning what data matters and when it matters
        </li>
        <li>
          Understanding the markets view of the macro picture
        </li>
        <li>
          Knowing about market sentiment
        </li>
        <li>
          Best resources to use for learning about and trading macro
        </li>
      </ul>

      <b>Technical Analysis</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Why ‘Price Action’ is more important than technical analysis in the first instance
        </li>
        <li>
          Reasons why technical analysis works
        </li>
        <li>
          How to properly use technical analysis
        </li>
        <li>
          When you should trade technical analysis, and when you should ignore it
        </li>
        <li>
          How to trade multiple technical levels
        </li>
      </ul>

      <b>Trading Psychology:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Learning to overcome emotion in trading
        </li>
        <li>
          How to properly deal with big losses or a losing streak
        </li>
        <li>
          Understanding your place in the market and what you have to do to become successful
        </li>
        <li>
          When to listen, and when to close your ears off to other voices in the market
        </li>
        <li>
          Overcoming trade size fear
        </li>
        <li>
          Finding your best trading style
        </li>
      </ul>

      <b>TRisk & Trade Management</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          The first, and most important, lesson in risk management
        </li>
        <li>
          Learning how to plan trades properly, and when you should, or shouldn’t, change a trade plan
        </li>
        <li>
          Why you can lose more trades than you win and still make money
        </li>
        <li>
          Strategies to use to maximise profits and minimise losses
        </li>
        <li>
          Knowing when and how to trade with the market, or against it
        </li>
        <li>
          Truly understanding the art of using trading targets
        </li>
      </ul>

    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Ryan Littlestone",
    firstName: "Ryan",
    twitter: "@forexflowlive",
    headerText: "Success in trading needs investment in yourself, more than in markets.",
    videoUrlCode: "RzAfh1GhISU",
  }
  return (
    <>
      <Seo title='Trader Coach Ryan' />
      <GDPRConsent />
      <Header props={mentor} />
      <main>
        <>
          {<DiscountBanners mentor='ryan' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                  src={imgProfile}
                  alt={'Ryan Littlestone'}
                  />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">Managing Director, trader and analyst at ForexFlow.live</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                His trading blends technicals and fundamentals to develop strategies for both short and long-term trading.
                <br /><br />
                Risk management is his key priority for trading, as is trade management when in positions. He concentrates on trying to maximise winning trades so they run as far as possible, while making sure losing trades don’t.
                <br /><br />
                Ryan has made every mistake every trader has made but the years of experience means those mistakes are less and less frequent, and this is what he wants to impart to other traders, be they new and inexperienced, or already on the path but have hit some roadblocks.
                <br /><br />
                If you are struggling to make headway, or take that next step in trading, if you have a bad ratio of winners vs losers, or are struggling to understand the technical and fundamental aspect of trading, Ryan can tailor a range of sessions based on your needs, to help you progress as a trader.
              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>


          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $150</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $250.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=58719483"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="ryan">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=58719483" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
